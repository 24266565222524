<template>
	<div
		v-if="versionWorkflowStatus.id"
		class="caption"
	>
		<span class="font-weight-medium">{{ date }}</span><br/>
		<div v-if="userName" :class="`grey--text text--${darkenOrLighten}-2 mb-1`">{{ userName }}</div>
		<div class="mt-1">
			<v-avatar
				:color="versionWorkflowStatus.color"
				size="22"
				class="mr-1"
				small
			>
				<v-icon
					v-if="versionWorkflowStatus.icon"
					:class="$getContrastedTextColor(versionWorkflowStatus.color)"
					size="12"
				>
					{{ versionWorkflowStatus.icon }}
				</v-icon>
			</v-avatar>
			<span :style="`color: ${versionWorkflowStatus.color};`">{{ versionWorkflowStatus.name }}</span>
			<span class="mx-1">-</span>
			<i>{{ versionWorkflowStatus.workflow.name }}</i>
		</div>

	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	props: {
		versionWorkflowStatus: {
			type: Object,
			required: true
		},
	},
	computed: {
		...mapState({
			isDarkModeEnabled: state => state.user.darkMode
		}),
		darkenOrLighten() {
			return this.isDarkModeEnabled ? 'lighten' : 'darken'
		},
		userName() {
			if (!this.versionWorkflowStatus.user) {
				return null;
			}
			return `${this.versionWorkflowStatus.user.firstname} ${this.versionWorkflowStatus.user.lastname}`
		},
		date() {
			return this.$translateDateTime(this.versionWorkflowStatus.created_at, 'verylong')
		}
	}
}
</script>

